<template>
    <div>
        <h3>Compromisos sin revisión</h3>
        <Bar
          :chart-options="chartOptions"
          :chart-data="chartData"
        />
    </div>
</template>
<script>
    import { Bar } from 'vue-chartjs/legacy'
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
    import ProyectoForm from "@/views/formulario/ProyectoForm.vue"
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)

    export default {
        name: 'BarChart',
        components: {
            Bar,
            ProyectoForm
        },
        props: {
            label: {
                type: Array,
                required: true
            },
            backgroundColors: {
                type: Array,
                required: true
            },
            datos: {
                type: Array,
                required: true
            },
            datos2: {
                type: Array,
                required: true
            },
            indexAxis: {
                type: String,
                default: 'y'
            }
        },
        data() {
            return {
                chartData: {
                    labels: this.label.map(item => item.nombre),
                    datasets: [
                        {
                            label: 'Con estado',
                            backgroundColor: this.backgroundColors[0], 
                            data: this.datos
                        },
                        {
                            label: 'Sin revisión',
                            backgroundColor: this.backgroundColors[1], 
                            data: this.datos2
                        }
                    ]
                },
                chartOptions: {
                    indexAxis: this.indexAxis,
                    responsive: true,
                    maintainAspectRatio: false,
                    onClick: (event, elements, chart) => {
                        if (elements.length !== 0) {
                            let indice = elements[0].index;
                            let labelClicado = this.label[indice]
                            this.$emit('bar-clicked', labelClicado)
                        }         
                    },
                    plugins: {
                        datalabels: {
                            color: '#000',
                            align: 'end', 
                            formatter: (value, context) => {
                                return ''
                                /* let total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                                if (value === 0){
                                    return ''
                                }
                                let percentage = (value / total) * 100;
                                let roundedPercentage = Math.round(percentage * 100) / 100;
                                return roundedPercentage.toFixed(2) + '% ' + '(' + value + ')'; */
                            }
                        },
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem) => {
                                let label = tooltipItem.dataset.label;
                                let index = tooltipItem.dataIndex;
                                let totalCompromisos = this.label[index].totalCompromisos;
                                return `${label}: ${tooltipItem.raw}, Total compromisos: ${totalCompromisos}`;
                                }
                            }
                        },
                    },
                    scales: {
                        x: {
                            display: true,
                            stacked: true,
                            ticks: {
                                display: true 
                            }
                        },
                        y: {
                            display: true,
                            stacked: true,
                        }
                    }
                }
            }
        },
        watch: {
            // Cuando cambian los compromisos, actualiza el gráfico
            label: {
                handler(newVal) {
                    this.updateChartData(newVal, this.datos);
                },
                deep: true
            },
            datos: {
                handler(newVal) {
                    this.updateChartData(this.label, newVal);
                },
                deep: true
            }
        },
        methods: {
            updateChartData(labelsData, datosData) {
                this.$set(this.chartData, 'labels', labelsData.map(item => item.nombre));
                this.$set(this.chartData.datasets[0], 'data', datosData);
            }
        }
    }
</script>

  