<template>
    <v-row justify="end" class="mr-1">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <v-card>
            <v-card-title>
                <span class="text-h5">{{title?title:titleOpen}}</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation :disabled="disabled">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <text-field-component label="Nombre *" ref="tfNombre" :valor="nombre" required :disabled="disabled"></text-field-component>
                            </v-col>
                            <v-col cols="12" v-if="view_ == 'codigo'">
                                <text-field-component label="Número *" ref="tfNumero" :valor="numero" required :disabled="disabled"></text-field-component>
                            </v-col>
                            <v-col cols="6" v-if="view_ == 'codigo'">
                                <text-field-component label="Código corto *" ref="tfCodigoCorto" :valor="codigoCorto" required :disabled="disabled"></text-field-component>
                            </v-col>
                            <v-col cols="6" v-if="view_ == 'codigo'">
                                <text-field-component label="Código largo *" ref="tfCodigoLargo" :valor="codigoLargo" required :disabled="disabled"></text-field-component>
                            </v-col>
                            <v-col cols="6" v-if="view_ == 'codigo'">
                                <date-picker-component label="Fecha inicio *" ref="dpFechaInicio" :valor="fechaInicio" required></date-picker-component>
                            </v-col>
                            <v-col cols="6" v-if="view_ == 'codigo'">
                                <date-picker-component label="Fecha cierre" ref="dpFechaCierre" :valor="fechaCierre"></date-picker-component>
                            </v-col>
                            <v-col cols="4" v-if="view_ == 'codigo'">
                                <select-component label="Estado *" ref="cmbEstados" url="estados/categoria/D" :valor="estadoCodigo" required></select-component>
                            </v-col>
                            <v-col cols="4" v-if="view_ == 'codigo'">
                                <select-component label="Responsable" ref="sResponsable" url="user" itemValue="id" itemText="nombre" :valor="responsable"></select-component>  
                            </v-col>
                            <v-col cols="4" v-if="view_ == 'codigo'">
                                <select-component label="Subresponsable" ref="sSubResponsable" url="user" itemValue="id" itemText="nombre" :valor="subResponsable"></select-component>
                            </v-col>
                            <v-col cols="12" v-if="view_ == 'codigo'">
                                <text-field-component label="Adicional A" ref="tfAdicionalA" :valor="adicionalA" :disabled="disabled"></text-field-component>
                            </v-col>
                            <v-col cols="12" v-if="view_ != 'codigo'">
                                <text-area-component label="Descripción" ref="tfDescripcion" :valor="descripcion" :counterValue="255"></text-area-component>
                            </v-col>
                            <v-col cols="12" v-if="view_ == 'usuario'">
                                <select-component label="Perfil *" ref="scPerfil" url="/perfiles" :valor="perfil" required :disabled="disabled"></select-component>
                            </v-col>
                            <v-col cols="12" class="mt-4" v-if="view_ == 'usuario'">
                                <text-field-component label="Email *" ref="tfEmail" :valor="email" type="email" required :disabled="disabled"></text-field-component>
                            </v-col>
                            <v-col cols="12" v-if="view_ == 'usuario'">
                                <text-field-component label="Contraseña *" ref="tfPassword" :valor="password" type="password" :disabled="disabled"></text-field-component>
                            </v-col>
                            <v-col cols="12" v-if="view_ == 'estado'">
                                <select-component label="Categoría *" ref="scCategoria" :data="categoriasEstado" :valor="categoria" required></select-component>
                            </v-col>
                            <v-col cols="2" v-if="view_ != 'codigo'">
                                <switch-component label="Estado" ref="swEstado" :valor="estado"></switch-component>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <small v-if="!disabled">* Indica campo obligatorios</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="ma-2"
                    large
                    @click="cancelar"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    v-if="!disabled"
                    class="ma-2"
                    dark
                    color="cyan"
                    large
                    @click="guardar"
                >
                    <v-icon left>
                        mdi-content-save
                    </v-icon>
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</template>

<script>
    import axios from 'axios'
    import { EventBus } from "@/event/event"
    import { perfilStore } from "@/store/PerfilStore"

    export default {
        props: {
            url: {
                type: String
            },
            view: {
                type: String
            },
            title: {
                type: String
            }
        },
        data() {
            return {
                disabled: false,
                dialog: false,
                valid: true,
                id: '',
                nombre: '',
                descripcion: '',
                estado: true,
                password: '',
                email: '',
                url_: this.url,
                view_: this.view,
                perfil: '',
                titleOpen: '',
                perfilStore: perfilStore(),
                categoriasEstado: [
                    {id: 'P', nombre: 'Unidad Fiscalizable'},
                    {id: 'C', nombre: 'Compromiso'},
                    {id: 'V', nombre: 'Visita'}
                ],
                categoria: '',
                numero: '',
                codigoCorto: '',
                codigoLargo: '',
                fechaInicio: '',
                fechaCierre: '',
                adicionalA: '',
                responsable: '',
                subResponsable: '',
                estadoCodigo: '',
            };
        },
        methods: {
            guardar() {
                if (this.$refs.form.validate()) {
                    //this.$refs.load.open();
                    let data
                    let email = ''
                    let perfil_id = 0
                    let password = ''
                    let categoria = ''

                    if (this.view_ != 'codigo') {
                        if (this.view_ == 'usuario') {
                            email = this.$refs.tfEmail.getValue()
                            perfil_id = this.$refs.scPerfil.getValue()
                            if (this.$refs.tfPassword.getValue() == null || this.$refs.tfPassword.getValue() == '') {
                                password = null
                            } else {
                                password = this.perfilStore.encryptData(this.$refs.tfPassword.getValue())
                            }
                        } else if (this.view_ == 'estado') {
                            categoria = this.$refs.scCategoria.getValue().id
                        }
                            data = {
                                nombre: this.$refs.tfNombre.getValue(),
                                descripcion: this.$refs.tfDescripcion.getValue(),
                                activo: this.$refs.swEstado.getValue(),
                                password: password,
                                perfil_id: perfil_id == 0 ? perfil_id : perfil_id.id,
                                email: email,
                                categoria: categoria
                            };
                    } else {
                        data = {
                            nombre: this.$refs.tfNombre.getValue(),
                            numero: this.$refs.tfNumero.getValue(),
                            codigo_corto: this.$refs.tfCodigoCorto.getValue(),
                            codigo_largo: this.$refs.tfCodigoLargo.getValue(),
                            fecha_inicio: this.$refs.dpFechaInicio.getValue(),
                            fecha_cierre: this.$refs.dpFechaCierre.getValue(),
                            estado_id: this.$refs.cmbEstados.getValue().id,
                            responsable: this.$refs.sResponsable.getValue().id,
                            sub_responsable: this.$refs.sSubResponsable.getValue().id,
                            adicional_a: this.$refs.tfAdicionalA.getValue()
                        }
                    }
                    if (this.id != '') {
                        axios.put(this.url_+'/' + this.id, data)
                        .then(response => {
                            EventBus.$emit('refresh-table', response)
                            this.afterService()
                        })
                        .catch(error => {
                            EventBus.$emit('refresh-table', error.response)
                            this.afterService()
                        });
                    } else {
                        axios.post(this.url_, data)
                        .then(response => {
                            EventBus.$emit('refresh-table', response)
                            this.afterService()
                        })
                        .catch(error => {
                            EventBus.$emit('refresh-table', error.response)
                            this.afterService()
                        });
                    }
                }
            },
            cancelar() {
                this.$refs.form.reset();
                this.$refs.form.resetValidation();
                this.dialog = false;
            },
            open(item, disabled, url, view) {
                if (view == 'codigo') {
                    this.titleOpen = 'Código DSS'
                } else {
                    this.titleOpen = view
                }
                this.url_ = url
                this.view_ = view
                this.id = ''
                if (this.$refs.form && this.view_ != 'codigo') {
                    this.$refs.swEstado.setValue(true);
                } else {
                    this.estado = true
                }
                if (item) {
                    this.id = item.id
                    if (this.$refs.form) {
                        this.$refs.tfNombre.setValue(item.nombre);
                        if (this.view_ != 'codigo'){
                            this.$refs.tfDescripcion.setValue(item.descripcion);
                            this.$refs.swEstado.setValue(item.activo);
                            if (this.view_ == "usuario") {
                                this.$refs.scPerfil.setValue(item.perfil)
                                this.$refs.tfEmail.setValue(item.email)
                            } else if (this.view_ == "estado") {
                                this.$refs.scCategoria.setValue(this.categoriasEstado.find(({id}) => id === item.categoria))
                            }
                        } else if (this.view_ == 'codigo') {
                            
                            this.$refs.tfNumero.setValue(item.numero)
                            this.$refs.tfCodigoCorto.setValue(item.codigo_corto)
                            this.$refs.tfCodigoLargo.setValue(item.codigo_largo)
                            this.$refs.dpFechaInicio.setValue(item.fecha_inicio)
                            this.$refs.dpFechaCierre.setValue(item.fecha_cierre)
                            this.$refs.cmbEstados.setValue(item.estado)
                            this.$refs.sResponsable.setValue(item.responsable)
                            this.$refs.sSubResponsable.setValue(item.sub_responsable)
                            this.$refs.tfAdicionalA.setValue(item.adicional_a)
                            
                        }
                    } else {
                        this.nombre = item.nombre;
                        this.descripcion = item.descripcion;
                        this.estado = item.activo;
                        if (this.view_ == "usuario") {
                            this.perfil = item.perfil
                            this.email = item.email
                        } else if (this.view_ == "estado") {
                            this.categoria = this.categoriasEstado.find(({id}) => id === item.categoria)
                        } else if (this.view_ == 'codigo') {
                            this.numero = item.numero
                            this.codigoCorto = item.codigo_corto
                            this.codigoLargo = item.codigo_largo
                            this.fechaInicio = item.fecha_inicio
                            this.fechaCierre = item.fecha_cierre
                            this.estadoCodigo = item.estado
                            this.responsable = item.responsable
                            this.subResponsable = item.sub_responsable
                            this.adicionalA = item.adicional_a
                        }
                    }
                }
                this.disabled = disabled;
                this.dialog = true;
            },
            afterService() {
                //this.$refs.load.close();
                this.cancelar()
                const timestamp = new Date().getTime();
            }
        }
    }
</script>