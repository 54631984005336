<template>
  <v-container>
    <view-pdf-component ref="refPdf"></view-pdf-component>
    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
      <h4>Bienvenido</h4>
    </div>
    <v-row>
      <v-col cols="4">
        <select-component :label="labelSelectTitular" ref="sTitular" :url="'/titular/proyecto/' + proyectoId + '/' + user_id" @selected-value="handleTitularSeleccionado" @clear-selected="clearSelected('titular')" :valor="titular" :key="titularKey" :selectedFirst="selectedFirstTitular" :clearable="true" :disabled="disabledTitular" @primer-titular="primerTitular"></select-component>
      </v-col>
      <v-col cols="4">
        <select-component :label="labelSelectProyecto" ref="sProyecto" :url="'/proyecto/titular/' + titularId" @selected-value="handleProyectoSeleccionado" @clear-selected="clearSelected('uf')" :valor="proyecto" :key="proyectoKey" :clearable="true" @primer-proyecto="primerProyecto" :selectedFirst="selectedFirstProyecto" :disabled="disabledUF" name="UFHome"></select-component>
      </v-col>
      <v-col v-if="mostrarDatos">
        <a @click="ficha()">Ir a Ficha Unidad Fiscalizable...</a>
      </v-col>
    </v-row>
    <v-row v-if="mostrarDatos">
      <v-col sm="4" style="max-height: 650px;">
        <v-card max-width="820" max-heigth="30px" elevation="10">
          <v-card-text style="text-align: center;">
            <BarChart 
              :label="['Cumple', 'No Cumple', 'No Aplica', 'Sin Revisión']"
              :backgroundColors="['#ADD8E6', '#FFCCCB', '#FFA07A', '#D3D3D3']"
              :datos="valoresChart"
              :key="keyChart"
              :proyecto="proyectoSeleccionado"
            ></BarChart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="8" class="mt-1">
        <v-row>
          <v-col sm="4">
            <v-card max-height="80" elevation="10">
              <v-card-text style="text-align: center; padding-top: 0px;">
                <div>Compromisos cargados:</div>
                <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                  {{ compromisos }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="4">
            <v-card max-height="80" elevation="10">
              <v-card-text style="text-align: center; padding-top: 0px;">
                <div>Visitas programadas:</div>
                <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                  {{ visitasProgramadas }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="4">
            <v-card max-height="80" elevation="10">
              <v-card-text style="text-align: center; padding-top: 0px;">
                <div>Visitas Finalizadas:</div>
                <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                  {{ visitasFinalizadas }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card max-heigth="30px" elevation="10">
              <v-card-text style="text-align: center;">
                <Calendario heightCal="350px" :key="keyChart" :proyectoId="proyectoSeleccionado.id"></Calendario>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card max-heigth="30px" elevation="10">
          <v-card-title>
            Eventos
          </v-card-title>
          <v-card-text style="text-align: center;">
            <table-component :headers="headers" :url="'eventosAgenda/'+proyectoSeleccionado.id" view="ficha-evento" labelSearch="Búsqueda de eventos" :key="contador" :showAdd="false"></table-component>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <BarChartCompromisosGestSemanal
          :label="cantCompromisosGestionSemanaUF"
          :backgroundColors="['#08697e']"
          :datos="cantCompromisosGestionTotalUF"
          :key="keyChart"
        ></BarChartCompromisosGestSemanal>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="12" class="mt-6">
            <v-card max-height="80" elevation="10">
              <v-card-text style="text-align: center; padding-top: 0px;">
                <div>Compromisos incompletos:</div>
                <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                  {{ compromisosIncompletosUF }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <br>
          <v-col cols="12" class="mt-6">
            <v-card max-height="80" elevation="10">
              <v-card-text style="text-align: center; padding-top: 0px;">
                <div>Compromisos sin revisión:</div>
                <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                  {{ compromisosSinEstadoUF }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <template v-if="mostrarDatosTitular && (perfilStore.decryptData(perfil_id) == perfilStore.titular || perfilStore.decryptData(perfil_id) == perfilStore.encargadoTitular)">
      <v-row class="justify-center">
        <v-col sm="9" class="mt-1">
          <v-row class="justify-center">
            <v-col sm="3">
              <v-card max-height="80" elevation="10">
                <v-card-text style="text-align: center; padding-top: 0px;">
                  <div>Unidades Fiscalizables:</div>
                  <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                    {{ cantidadUf }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col sm="3">
              <v-card max-height="80" elevation="10">
                <v-card-text style="text-align: center; padding-top: 0px;">
                  <div>RCAs:</div>
                  <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                    {{ cantidadRcas }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col sm="3">
              <v-card max-height="80" elevation="10">
                <v-card-text style="text-align: center; padding-top: 0px;">
                  <div>Compromisos:</div>
                  <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                    {{ cantidadCompromisos }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col sm="2">
          <v-card max-height="100" elevation="10">
            <v-card-text style="text-align: center; padding-top: 0px;">
              <div>Cumple:</div>
              <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                {{ cantidadCumple }}
              </p>
              <label v-if="cantidadCompromisos > 0 && cantidadCumple !== null" style="color: green">
                {{ calcularPorcentaje(cantidadCumple, cantidadCompromisos) + '%' }}
              </label>
              <label v-else style="color: green">Cargando...</label>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="2">
          <v-card max-height="100" elevation="10">
            <v-card-text style="text-align: center; padding-top: 0px;">
              <div>No Cumple:</div>
              <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                {{ cantidadNoCumple }}
              </p>
              <label v-if="cantidadCompromisos > 0 && cantidadNoCumple !== null" style="color: green">
                {{ calcularPorcentaje(cantidadNoCumple, cantidadCompromisos) + '%' }}
              </label>
              <label v-else style="color: green">Cargando...</label>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="2">
          <v-card max-height="100" elevation="10">
            <v-card-text style="text-align: center; padding-top: 0px;">
              <div>No aplica:</div>
              <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                {{ cantidadCompromisosOtros }}
              </p>
              <label v-if="cantidadCompromisos > 0 && cantidadCompromisosOtros !== null" style="color: green">
                {{ calcularPorcentaje(cantidadCompromisosOtros, cantidadCompromisos) + '%' }}
              </label>
              <label v-else style="color: green">Cargando...</label>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="2">
          <v-card max-height="100" elevation="10">
            <v-card-text style="text-align: center; padding-top: 0px;">
              <div>Sin revisión:</div>
              <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                {{ cantidadCompromisosNull }}
              </p>
              <label v-if="cantidadCompromisos > 0 && cantidadCompromisosNull !== null" style="color: green">
                {{ calcularPorcentaje(cantidadCompromisosNull, cantidadCompromisos) + '%' }}
              </label>
              <label v-else style="color: green">Cargando...</label>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-if="mostrarDatosJefatura && (perfilStore.decryptData(perfil_id) == perfilStore.administrador || perfilStore.decryptData(perfil_id) == perfilStore.jefatura)">
      <v-row>
        <v-col cols="4">
          <v-card max-height="80" elevation="10">
            <v-card-text style="text-align: center; padding-top: 0px;">
              <div>Total de UF creadas en sistema:</div>
              <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                {{ cantidadUf }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card max-height="80" elevation="10">
            <v-card-text style="text-align: center; padding-top: 0px;">
              <div>Total de compromisos creados en sistema:</div>
              <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                {{ cantidadCompromisos }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <BarChartUFMensual 
            :label="cantUFMensualMes"
            :backgroundColors="['#08697e']"
            :datos="cantUFMensualTotal"
            :key="keyChart"
          ></BarChartUFMensual>
        </v-col>
        <v-col cols="4">
          <BarChartCompromisoMensual
            :label="cantCompromisosMensualMes"
            :backgroundColors="['#08697e']"
            :datos="cantCompromisosMensualTotal"
            :key="keyChart"
          ></BarChartCompromisoMensual>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <BarChartCompromisosGestSemanal
            :label="cantCompromisosGestionSemana"
            :backgroundColors="['#08697e']"
            :datos="cantCompromisosGestionTotal"
            :key="keyChart"
          ></BarChartCompromisosGestSemanal>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <h3>Unidad Fiscalizable por titular en SIGSA</h3>
          <table-component :headers="headersTitulares" :data="titulares" view="informacion-titulares" :showAdd="false" :key="keyTabla" :exportExcel="false" :isSeleccionColumnas="false"></table-component>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <BarChartCompromisosIncompletos
            :label="cantCompromisosIncompletosTitular"
            :backgroundColors="['#08697e', '#Ff0000']"
            :datos="cantCompromisosProyecto"
            :datos2="cantCompromisosIncompletosTotal"
            :key="keyChart"
            @bar-clicked="handleBarClick"
          ></BarChartCompromisosIncompletos>
        </v-col>
        <v-col cols="4">
          <BarChartCompromisosSinEstado
            :label="cantCompromisosSinEstadoTitular"
            :backgroundColors="['#08697e', '#Ff0000']"
            :datos="cantCompromisosSinEstadoProyecto"
            :datos2="cantCompromisosSinEstadoTotal"
            :key="keyChart"
            @bar-clicked="handleBarClick"
          ></BarChartCompromisosSinEstado>
        </v-col>
      </v-row>
    </template>
</v-container>
</template>

<script>
  import Calendario from "@/components/calendario/Calendario.vue";
  import BarChart from "@/components/chart/BarChart.vue";
  import axios from "axios";
  import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
  import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
  import "leaflet/dist/leaflet.css";
  import proj4 from 'proj4'
  import { perfilStore } from "@/store/PerfilStore";
  import BarChartUFMensual from "@/components/chart/BarChartUFMensual.vue";
  import BarChartCompromisoMensual from "@/components/chart/BarChartCompromisoMensual.vue";
  import BarChartCompromisosGestSemanal from "@/components/chart/BarChartCompromisosGestSemanal.vue";
  import BarChartCompromisosIncompletos from "@/components/chart/BarChartCompromisosIncompletos.vue";
  import BarChartCompromisosSinEstado from "@/components/chart/BarChartCompromisosSinEstado.vue";
  import { EventBus } from "@/event/event"

  export default {
    components: { 
      BarChart, 
      Calendario, 
      LMap,
      LTileLayer,
      LMarker,
      LPopup, 
      'v-marker-cluster': Vue2LeafletMarkerCluster,
      BarChartUFMensual,
      BarChartCompromisoMensual,
      BarChartCompromisosGestSemanal,
      BarChartCompromisosIncompletos,
      BarChartCompromisosSinEstado
    },
    data: () => ({
      reveal: false,
      compromisos: 0,
      visitasProgramadas: 0,
      visitasFinalizadas: 0,
      zoom: 4,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: "© OpenStreetMap contributors",
      markers: [],
      markerClusterOptions: {
        // Opciones del agrupamiento de marcadores
        spiderfyOnMaxZoom: true,
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
      },
      proyecto: null,
      titular: null,
      proyectoSeleccionado: null,
      proyectoId: 0,
      mostrarDatos: false,
      valoresChart: [],
      keyChart: 0,
      contador: 0,
      headers: [
        { text: 'Nombre', value: 'titulo', filtro:false },
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Fecha evento', value: 'fecha', filtro:false },
        { text: 'Estado', value: 'visita.estado.nombre', filtro:false, width: '100px' },
        { text: 'Hora inicio', value: 'hora_inicio', filtro:false },
        { text: 'Hora fin', value: 'hora_fin', filtro:false },
        { text: 'Lugar', value: 'lugar', filtro:false },
        { text: 'Acciones', value: 'actions', filtro:false, align: 'center'},
      ],
      titularId: 0,
      titularKey: 0,
      selectedFirstTitular: false,
      proyectoKey: 0,
      mostrarDatosTitular: false,
      perfilStore: perfilStore(),
      perfil_id: null,
      disabledTitular: false,
      selectedFirstProyecto: false,
      labelSelectTitular: 'Seleccione un titular',
      labelSelectProyecto: 'Seleccione Unidad Fiscalizable',
      cantidadUf: 0,
      cantidadRcas: 0,
      cantidadCompromisos: 0,
      cantidadCumple: 0,
      cantidadNoCumple: 0,
      cantidadCompromisosOtros: 0,
      cantidadCompromisosNull: 0,
      disabledUF: false,
      user_id: 0,
      cantUFMensualMes: [],
      cantUFMensualTotal: [],
      cantCompromisosMensualMes: [],
      cantCompromisosMensualTotal: [],
      cantCompromisosGestionSemana: [],
      cantCompromisosGestionTotal: [],
      mostrarDatosJefatura: true,
      headersTitulares: [
        { text: 'Titular', value: 'titular.nombre' },
        { text: 'UFs', value: 'ufs.length', align: 'center' },
        { text: 'Compromisos', value: 'compromisosTotal', align: 'center' }
      ],
      titulares: [],
      keyTabla: 0,
      cantCompromisosIncompletosTitular: [],
      cantCompromisosIncompletosTotal: [],
      cantCompromisosSinEstadoTitular: [],
      cantCompromisosSinEstadoTotal: [],
      cantCompromisosProyecto: [],
      cantCompromisosSinEstadoProyecto: [],
      cantCompromisosGestionSemanaUF: [],
      cantCompromisosGestionTotalUF: [],
      compromisosIncompletosUF: 0,
      compromisosSinEstadoUF: 0
    }),
    methods: {
      async handleTitularSeleccionado(value) {
        if (value) {
          if (value != null) {
            this.titularId = value.id
            this.proyectoKey++
          } else {
            this.titularId = 0
            this.proyectoKey++
            this.titularKey++
          }
        }
      },
      async handleProyectoSeleccionado(value) {
        if (value) {
          if (value != null) {
            this.mostrarDatosJefatura = false
            this.proyectoSeleccionado = value
            this.proyectoId = this.proyectoSeleccionado.id
            const params = {
              id: this.proyectoId
            }
            await axios.get('estado/compromiso', {params}).then((response) => {
              this.valoresChart = response.data.body.items
              this.getProyecto(this.proyectoId)
              this.mostrarDatos = true
              this.mostrarDatosTitular = false
            })
            this.getCompromisosGestionSemanalUF(this.proyectoId)
            this.keyChart++
            ++this.contador
            this.selectedFirstTitular = true
            //this.titularKey++
          } else {
            this.proyectoId = 0
            this.selectedFirstTitular = false
            //this.titularKey++
          }
        }
      },
      ficha() {
        if (this.proyectoSeleccionado) {
          this.$router.push({name: 'ficha', query: {proyecto: this.proyectoSeleccionado.id}})
        }
      },
      async getProyecto(proyecto_id) {
        const params = {
          id: proyecto_id
        }
        await axios.get("/dashboard/contador", {params})
        .then((response) => {
          this.compromisos = response.data.body.items.compromisos
          this.visitasProgramadas = response.data.body.items.visitasProgramadas
          this.visitasFinalizadas = response.data.body.items.visitasFinalizadas
        }).catch((error) => {
            console.error(error)
        })
      },
      async coordenadas() {
        await axios.get("/proyectos/coordenadas")
        .then((response) => {
          const arreglo = response.data.body.items
          let aux = 1;
          arreglo.forEach(element => {
            switch (element.tipo_coordenada) {
              case 'DIR':
              const nominatimUrl = 'https://nominatim.openstreetmap.org/search';
              const url = `${nominatimUrl}?format=json&addressdetails=1&q=${encodeURIComponent(element.direccion)}&countrycodes=CL`;

              // Realiza la solicitud CORS directamente desde el navegador
                fetch(url)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 0) {
                        const firstResult = data[0];
                        const latitude = parseFloat(firstResult.lat)
                        const longitude = parseFloat(firstResult.lon)
                        this.markers.push({id: aux, latLng:[latitude, longitude], popupContent: element.nombre})
                    } else {
                        console.error('No se encontraron coordenadas para la dirección proporcionada.');
                    }
                }).catch(error => {
                    console.error('Error al obtener coordenadas:', error);
                });
                break;
              case 'UTM':
                const latitud = parseFloat(element.latitud.replace(',', '.'));
                const longitud = parseFloat(element.longitud.replace(',', '.'));
                proj4.defs("EPSG:32718", "+proj=utm +zone=18 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs");
                proj4.defs("EPSG:32719", "+proj=utm +zone=19 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs");
                let zonaUtm = "EPSG:32719"
                if (element.zona == 18) {
                    zonaUtm = "EPSG:32718"
                }
                let latLng = proj4(zonaUtm, "EPSG:4326", [latitud, longitud]);
                this.markers.push({id: aux, latLng:[latLng[1], latLng[0]], popupContent: element.nombre})
                aux++
                break;
              case 'DEC':
                const latitude = parseFloat(element.latitud)
                const longitude = parseFloat(element.longitud)
                this.markers.push({id: aux, latLng:[latitude, longitude], popupContent: element.nombre})
                aux++
                break;
            }
          });
        }).catch((error) => {
          console.error(error)
        })
      },
      async getCoordinatesFromAddress(address) {
        const nominatimUrl = 'https://nominatim.openstreetmap.org/search';
        const url = `${nominatimUrl}?format=json&addressdetails=1&q=${encodeURIComponent(address)}&countrycodes=CL`;

        // Realiza la solicitud CORS directamente desde el navegador
        fetch(url)
        .then(response => response.json())
        .then(data => {
            if (data.length > 0) {
                const firstResult = data[0];
                const latitude = parseFloat(firstResult.lat)
                const longitude = parseFloat(firstResult.lon)
                return [latitude, longitude]
            } else {
                console.error('No se encontraron coordenadas para la dirección proporcionada.');
            }
        }).catch(error => {
            console.error('Error al obtener coordenadas:', error);
        });
      },
      clearSelected(origen) {
        if (this.perfilStore.decryptData(localStorage.getItem('perfil_id')) != this.perfilStore.titular && this.perfilStore.decryptData(localStorage.getItem('perfil_id')) != this.perfilStore.encargadoTitular) {
          this.proyectoId = 0
          this.titularId = 0
          this.titularKey++
          this.proyectoKey++
          this.mostrarDatos = false
          if (this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.administrador || this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.jefatura) {
            this.mostrarDatosJefatura = true
          }
        } else {
          if (origen == 'titular') {
            this.titularId = 0
          }
          this.obtenerValoresTarjetasTitular()
          this.proyectoKey++
          this.mostrarDatos = false
          this.mostrarDatosTitular = true
        }
      },
      async obtenerTitular() {
        await axios.get('/titular/asignado').then((response) => {
          this.$refs.sTitular.setValue(response.data.body.items[0])
          this.titular = response.data.body.items[0]
          this.titularId = response.data.body.items[0].id
          this.proyectoKey++
        }).catch((error) => {
          console.error(error)
        })
      },
      primerProyecto(value, model, ok) {
        if (ok) {
          if (this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.titular || this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.encargadoTitular) {
            this.selectedFirstProyecto = value
            if (value) {
              //this.disabledUF = true
              //this.labelSelectProyecto = ''
              //this.labelSelectTitular = ''
              if (model) {
                this.disabledUF = true
                this.handleProyectoSeleccionado(model)
              }
            } else {
              this.labelSelectProyecto = 'Seleccione Unidad Fiscalizable'
              this.labelSelectTitular = 'Seleccione un titular'
              this.mostrarDatosTitular = true
              this.mostrarDatos = false
              this.disabledUF = false
            }
          }
        }
      },
      primerTitular(value) {
        this.disabledTitular = value
        this.selectedFirstTitular = value
      },
      async obtenerValoresTarjetasTitular() {
        await axios.get('/informacion/uf/titular').then(response => {
          this.cantidadUf = response.data.body.items.cantidadUfs
          this.cantidadRcas = response.data.body.items.cantidadRcas
          this.cantidadCompromisos = response.data.body.items.cantidadCompromisos
          this.cantidadCumple = response.data.body.items.cantidadCompromisoCumple
          this.cantidadNoCumple = response.data.body.items.cantidadCompromisoNoCumple
          this.cantidadCompromisosOtros = response.data.body.items.cantidadCompromisosOtros
          this.cantidadCompromisosNull = response.data.body.items.cantidadCompromisosNull
        }).catch((error) => {
          console.error(error)
        })
      },
      calcularPorcentaje(parte, total){
        let porcentaje = ((parte/total) * 100).toFixed(2)
        if (porcentaje == 0) {
          return 0
        }
        return porcentaje
      },
      async obtenerValoresTarjetasJefatura() {
        //this.$refs.load.open();
        await axios.get('/informacion/jefatura').then(response => {
          this.cantidadUf = response.data.body.items.cantidadUfs
          this.cantidadCompromisos = response.data.body.items.cantidadCompromisos
          response.data.body.items.cantUFMensual.forEach(element => {
            this.cantUFMensualMes.push(element.mes)
            this.cantUFMensualTotal.push(element.total)
          });
          response.data.body.items.cantCompromisosMensual.forEach(element => {
            this.cantCompromisosMensualMes.push(element.mes)
            this.cantCompromisosMensualTotal.push(element.total)
          });
          response.data.body.items.cantCompromisosGestionSemanal.forEach(element => {
            this.cantCompromisosGestionSemana.push(element.semana)
            this.cantCompromisosGestionTotal.push(element.total)
          });
          response.data.body.items.compromisosIncompletos.forEach(element => {
            this.cantCompromisosIncompletosTitular.push({nombre: element.nombre, id: element.id, totalCompromisos: element.totalCompromisos})
            this.cantCompromisosIncompletosTotal.push(element.total)
          });
          response.data.body.items.compromisosProyecto.forEach(element => {
            this.cantCompromisosProyecto.push(element.total)
          });
          response.data.body.items.compromisosSinEstado.forEach(element => {
            this.cantCompromisosSinEstadoTitular.push({nombre: element.nombre, id: element.id, totalCompromisos: element.totalCompromisos})
            this.cantCompromisosSinEstadoTotal.push(element.total)
          });
          response.data.body.items.compromisosProyectoSinEstado.forEach(element => {
            this.cantCompromisosSinEstadoProyecto.push(element.total)
          });
          this.titulares = response.data.body.items.titulares
          ////this.$refs.load.close();
        }).catch((error) => {
          console.error(error)
        })
        ++this.keyTabla
      },
      handleBarClick(value) {
        this.$refs.sProyecto.setValue(value)
        this.handleProyectoSeleccionado(value)
      },
      async getCompromisosGestionSemanalUF(id) {
        this.cantCompromisosGestionSemanaUF = []
        this.cantCompromisosGestionTotalUF = []
        this.compromisosSinEstadoUF = []
        this.compromisosIncompletosUF = []
        await axios.get('/informacion/ficha/gestion/'+ id).then(response => {
          response.data.body.items.cantCompromisosGestionSemanal.forEach(element => {
            this.cantCompromisosGestionSemanaUF.push(element.semana)
            this.cantCompromisosGestionTotalUF.push(element.total)
          });
          this.compromisosSinEstadoUF = response.data.body.items.sinEstado
          this.compromisosIncompletosUF = response.data.body.items.incompletos[0].total_incompletos
        }).catch((error) => {
          console.error(error)
        })
      },
      openInforme(base64, doc_id, nombre) {
        this.$nextTick(() => {
          this.$refs.refPdf.open(base64, doc_id, nombre)
        });
      }
    },
    mounted() {
      EventBus.$on('open-informe', (base64, id, nombre) => {
        this.openInforme(base64, id, nombre)
      });
      //this.$refs.load.open();
      /* this.coordenadas(); */
      this.perfil_id = localStorage.getItem('perfil_id')
      if (this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.titular || this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.encargadoTitular) {
        this.disabledTitular = true
        //this.obtenerTitular()
        this.obtenerValoresTarjetasTitular()
      } else if (this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.administrador || this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.jefatura) {
        this.obtenerValoresTarjetasJefatura()
      } else if (this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.encargadoTitular) {
        
      }
      const parametro = this.$route.params.parametro;
      if (parametro) {
        this.$refs.sProyecto.setValue(parametro);
      }
    },
    beforeMount() {
      this.user_id = localStorage.getItem('id')
    }
  }
</script>

<style scoped>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.v-sheet.v-card {
  border-radius: 15px !important
}
.rotated-image {
    transform: rotate(-94deg); /* Puedes ajustar el ángulo de rotación según tus necesidades */
  }
</style>